export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la Orden de Compra"])},
        "LABELS": {
          "ASSORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surtida 100%"])},
          "EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
          "TOTAL_RECEPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Recepción Total"])},
          "PARTIAL_RECEPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Recepción Parcial"])}
        },
        "PLACEHOLDERS": {
          "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre Artículo"])}
        }
      }
    }
  })
}
