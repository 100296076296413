import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import ReceptionsService from '@/core/services/receptions/ReceptionsService';
import TableReceptionDetailsState from '../../models/TableReceptionDetailsState';
import ReceptionDetailRow from '@/models/receptions/ReceptionDetailRow';
import FiltersReceptionDetails from '@/models/receptions/filters/FilterReceptionDetails';
import TableReceptionDetailsService from '@/core/services/receptions/interfaces/TableReceptionDetailsService';
import OptionSelect from '@/models/shared/OptionSelect';
import ProductsService from '@/core/services/products/ProductsService';
import ProductSearchComboService from '@/core/services/products/interfaces/productSearchComboService';

@Module({dynamic: true, store, namespaced: true, name: Modules.TableReceptionDetailsModule})
class TableReceptionDetailsModule extends VuexModule implements TableReceptionDetailsState {

    records = [] as ReceptionDetailRow[];
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10//defecto
    } as PaginationRequest
    filters = {
        articleCode: ''
        , articleIds: []
        , productName: ""
    } as FiltersReceptionDetails;
    selects = {
        articleOptions: [] as OptionSelect[]
    }
    loading = false;

    get getRowById()  { 
        return (id: string) => { 
            return this.records.find(r => r.id == id);
        };
    }
    get filtersAreApplied(): boolean { 
        return this.filters.articleCode != '' || this.filters.articleIds.length > 0;
    }

    get allRecords() {
        return this.records;
    }

    get getTotalRecords() {
        return this.totalRecords;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: ReceptionDetailRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = {
           articleCode: ''
            , articleIds: []
            , productName: ''
         } as FiltersReceptionDetails;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersReceptionDetails>) {      
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](idDetCantRecpt = ""){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        const receptionId = this.context.rootState[Modules.TableReceptionModule]?.receptionSelected?.id ?? '';
        if (receptionId && receptionId != '') {
            const resp = (await (service.searchTableReceptionDetails(receptionId, this.pagination, this.filters)
                .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
                {
                    data: [] as ReceptionDetailRow[]
                    , totalPages: 0
                    , currentPage: this.pagination.currentPage
            }
            
            //ajustamos la cantidad pendiente
            const data = resp.data.map(row => {
                return {...row, quantityPending: (row.quantity - row.quantityReceived) < 0 ? 0 : row.quantity - row.quantityReceived}
             });

            // if(idDetCantRecpt.length > 0) {
            //     const parts = idDetCantRecpt.split("|")
            //     if(parts.length == 2) {
            //         data = resp.data.map(row => {
            //             if(row.id === parts[0]) {
            //                 return {
            //                     ...row, 
            //                     quantityPending: ((row.quantity - row.quantityReceived) < 0 ? 0 : row.quantity - row.quantityReceived) - parseInt(parts[1]),
            //                     quantityReceived: row.quantityReceived + parseInt(parts[1])
            //                 }
            //             } else {
            //                 return row;
            //             }
            //         });
            //     }
            // }
            
            this.context.commit(Mutations.SET_TABLE_DATA, data);
            this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
            this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);     
        }
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersReceptionDetails>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    @MutationAction
    async getComboArticle(filterName: string) {
         const comboOptions = await serviceProduct.searchProducts(filterName);
         const selects = { ...this.selects }
        if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, articleOptions: comboOptions.data ?? [] } }
    }
} 

const service = new ReceptionsService() as TableReceptionDetailsService;
const serviceProduct = new ProductsService() as ProductSearchComboService;

export default getModule(TableReceptionDetailsModule);
