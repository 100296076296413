import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "col-md-12 d-flex flex-column flex-row-fluid gap-7",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = {
  hidden: "",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modalFormTotalReception",
  ref: "btnForm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OCDetails = _resolveComponent("OCDetails")!
  const _component_TabContent = _resolveComponent("TabContent")!
  const _component_TotalReception = _resolveComponent("TotalReception")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("a", {
                  class: "nav-link text-active-primary pb-4 active",
                  "data-bs-toggle": "tab",
                  href: "#tab_OC_Details",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab(0)))
                }, "Registros a Visualizar")
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_TabContent, {
              id: "tab_OC_Details",
              active: _ctx.getActiveTab == 0
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OCDetails, {
                  purchaseOrderId: _ctx.getPurchaseOrderId,
                  onUpdatedHistory: _ctx.updateHistory
                }, null, 8, ["purchaseOrderId", "onUpdatedHistory"])
              ]),
              _: 1
            }, 8, ["active"])
          ])
        ])
      ])
    ]),
    _createElementVNode("button", _hoisted_7, null, 512),
    _createVNode(_component_TotalReception, {
      ref: "editForm",
      title: "Recepción Total",
      purchaseOrderId: _ctx.getPurchaseOrderId
    }, null, 8, ["purchaseOrderId"])
  ], 64))
}