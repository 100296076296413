import Excel from 'exceljs';
import path from 'path';
import * as downloadFile from './downloadFile';

const createExcelFileUrl = async (fields: FieldExcelObject[], object: any[], options: ConfigExcelCreate) => {
    //crear la url del proyecto
    const workbook = new Excel.Workbook();
    const worksheetName = options?.worksheetName ?? "Excel File";
    const worksheet = workbook.addWorksheet(worksheetName);
    options.fileName = options.fileName != '' ? options.fileName : 'Archivo Excel-' + new Date().getTime();
    const columns = [] as Partial<Excel.Column>[];
    let fieldKey = '';
    fields.forEach((field) => {
        fieldKey = field?.objectNameValue ?? ''; 
        if (field.isBlank || fieldKey != '') {
            columns.push({
                key: fieldKey
                , header: field.columName
                , isCustomWidth: true
                , width: 20
            })
        }
    });
    worksheet.columns = columns;
    object.forEach((item) => {
        worksheet.addRow(item, "");
    });
    //protejemos las columnas que no se deben editar
    /*worksheet.protect('', {
          formatCells: true,
          formatColumns: true,
          formatRows: true,
          insertRows: true,
          insertColumns: false,
          insertHyperlinks: true,
          deleteRows: false,
          deleteColumns: false,
          sort: false,
          autoFilter: true
        })
    const columnsExcel = ['A','B','C','D','E','F','G','I'];
    fields.forEach((field, index) => {
        if (!field.isBlank) {
           //proteger si no es blanco el valor
            worksheet.getCell(columnsExcel[index] +''+index).protection =  { locked: false};
        }
    });*/

    const buffer = await workbook.xlsx.writeBuffer();
    downloadFile.buffer(buffer, options.fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
}


interface FieldExcelObject {
    columName: string;
    //value?: string;
    objectNameValue: string;
    //indica si es un espacio en blanco
    isBlank?: boolean;
} 
 
interface ConfigExcelCreate { 
    worksheetName: string;
    fileName: string;
}   

export { createExcelFileUrl };