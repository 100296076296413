
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue';
import OCDetails from "./oc-details.vue";
//import HistoryReception from "./history-reception.vue";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
//import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import TabContent from './tab_content.vue';
import TotalReception from './total-reception.vue';
import tableModule from '@/store/modules/receptions/modules/table'
import {  useRouter } from 'vue-router';
import { extractParamValue } from "../../../common/utils";
import tableDetailModule from '@/store/modules/receptions/modules/table-details'
export default defineComponent({
    components: {
        OCDetails
        //, HistoryReception
        //, SelectOption
        , TabContent
        , TotalReception
    },
    props: {
        C0: {
            type: String,
            required: true
        },
        isTotalReception: {
            type: Boolean
            , default: false
        }
    },
    setup(props) {
        let warehouses = ref([]);
        let warehouseIdSelected = ref('');
        let freigtValue = ref(0);
        const editForm = ref(null);
        const btnForm = ref(null);   
        const tab_history = ref(null);   
        const router = useRouter();
        let updNum = ref(0);
        let activeTab = ref(0);

        onBeforeMount(async() => {
            //purchaseOrderId.value = extractParamValue(atob(props.C0), "purchaseOrderId");
            await getPrincipalData();
        });

        onMounted(() => {
            loadInitialData();
        });

        const comboWarehouses = computed(() => warehouses.value)
        const getPurchaseOrderId = computed(() => tableModule.getSelectedRow?.id ?? '');
        const getUpdNumHistory = computed(() => updNum.value);
        const getActiveTab = computed(() => activeTab.value);
        
        const getPrincipalData = async () => {
            if (!tableModule.getSelectedRow?.id) {
                if (!props.C0) {
                    router.go(-1);
                    return;
                } else { 
                    const receptionId: string = extractParamValue(atob(props.C0), "purchaseOrderId");
                    await tableModule.searchInfoReceptionSelected(receptionId).then(() => { 
                        //si se consulta entonces recargamos todo
                        loadInitialData();
                    });
                }
            }
            if (!getPurchaseOrderId.value) {
                router.go(-1);
                return
            }  
        }

        const loadInitialData = () => { 
            const folio = tableModule.getSelectedRow?.folio ?? '';
            setCurrentPageBreadcrumbs('Detalles', ["Módulos", "Compras", { name: "Recepciones", to: "shopping/receptions" }, `Recepción: ${folio}`]);
            tableDetailModule.SEARCH_TABLE_DATA();
            activeTab.value = 0;
        }

        const updateHistory = () => {
            updNum.value++;
        }

        const selectTab = (tab) => {
            activeTab.value = tab;
        }


        return {
            //refs
            warehouseIdSelected
            , freigtValue
            , editForm
            , btnForm
            , tab_history
            //Computeds
            , getPurchaseOrderId
            , comboWarehouses
            , getUpdNumHistory
            , updateHistory
            , getActiveTab

            //Functions
            , selectTab
        }
    }
})
