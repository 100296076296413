<template>
    <div class="tab-content">
        <div 
            class="tab-pane fade show"
            :class="{'active': active}"
            :id="id" role="tab-panel"
        >
            <div class="d-flex flex-column gap-7">
                <div class="card card-flush py-4">
                    <div class="card-body pt-0">
                        <div class="d-flex flex-wrap gap-5">
                            <div class="fv-row w-100 flex-md-root">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true,
            default: () => 'tabId'
        },
        active: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    setup(z) {
        let tmp = 123;
    },
})
</script>
