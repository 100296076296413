
import FilterDynamic from "@/components/filters/FiltersDynamic.vue";
import FiltersReceptionDetails from "@/models/receptions/filters/FilterReceptionDetails";
import { computed, defineComponent, ref } from "vue";
import tableDetailModule from '@/store/modules/receptions/modules/table-details'
import _ from "lodash";

export default defineComponent({
    components: {
        FilterDynamic
    },
    setup() {
        const filters = ref<FiltersReceptionDetails>({
            articleCode: tableDetailModule.filters.articleCode
            , articleIds: tableDetailModule.filters.articleIds
            , productName: tableDetailModule.filters.productName
        });
        const articleOptions = computed(() => tableDetailModule.selects.articleOptions);
        const filtersAreApplied = computed(() => tableDetailModule.filtersAreApplied)
        const applyFilters = () => {
            //filtros ha aplicar para los detalles de recepción
            tableDetailModule.UPDATE_FILTERS(filters.value)
        }
        const cleanFilters = () => {
            //filtros ha aplicar para los detalles de recepción
            filters.value = { articleCode: '', articleIds: [], productName: ''}
            applyFilters();
        }
        const searchArticles = _.debounce((filterText: string) => {
            //busca los articulos
            tableDetailModule.getComboArticle(filterText);
        }, 550, { leading: false, trailing: true });
        return {
            articleOptions
            , filters
            , filtersAreApplied
            , applyFilters
            , cleanFilters
            , searchArticles
        }

    }
})
