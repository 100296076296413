
import { defineComponent, ref, onMounted, toRefs, reactive, computed, watch, onUnmounted } from 'vue';
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import PaginatorV2 from "../../../components/c-paginator/PaginatorV2.vue"
import PartialReception from "./partial-reception.vue";
import TotalReception from "./total-reception.vue";
import tableDetailModule from '@/store/modules/receptions/modules/table-details'
import Filters from '../filters/Filters.vue'
import { createExcelFileUrl } from "@/core/helpers/excelTransform";
import tableModule from '@/store/modules/receptions/modules/table'
import { useRoute } from 'vue-router'
import ArticleInfo from '@/components/shared/articles/ArticleInfo.vue'
import { emit } from 'process';
export default defineComponent({
    components: {
        Datatable
        , Datarow
        , PaginatorV2
        , PartialReception
        , Filters
        , TotalReception
        , ArticleInfo
    },
    props: {
        purchaseOrderId: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const messageService = new SwalMessageService();
        const { purchaseOrderId } = toRefs(props);
        const idCollapse = ref('reception-detail-filters')
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Artículo", Type: "slot", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de Medida", Type: "text", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cant. Solicitada", Type: "text", VisibleInGrid: true },
            { PropName: "quantityReceived", HeadLabel: "Cant. Recepcionada", Type: "text", VisibleInGrid: true },
            { PropName: "quantityPending", HeadLabel: "Cant. Pendiente", Type: "text", VisibleInGrid: true },
            { PropName: "inputNumber", HeadLabel: "Recepcionar", Type: "slot", VisibleInGrid: true }
        ]);

        const configTableTotal = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Artículo", Type: "slot", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de Medida", Type: "text", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cant. Solicitada", Type: "text", VisibleInGrid: true },
            { PropName: "quantityReceived", HeadLabel: "Cant. Recepcionada", Type: "text", VisibleInGrid: true },
            { PropName: "quantityPending", HeadLabel: "Cant. Pendiente", Type: "text", VisibleInGrid: true }
        ]);

        const route = useRoute();
        const btnForm = ref();
        const editForm = ref();
        const isTotalReception = ref(false);
        let cantReceive = ref(0);
        let detailsToReceive = ref([] as { Id: string, ProductId: string, Cant: number,  Name: string}[] );
        const detailId = ref('');
        let textSearch = ref("");
        const getPurchaseOrderId = computed(() => tableModule.getSelectedRow?.id ?? '');
        onMounted(() => {
            const query = route.query;
            isTotalReception.value = query.isTotalReception?.toString().toLowerCase() == 'true';
            textSearch.value = "";
            tableDetailModule.UPDATE_FILTERS({ productName: textSearch.value.trim() });
        });

        onUnmounted(() => {
            tableDetailModule.SET_TOTAL_RECORDS(0);
        })

        watch(textSearch, async () => {
            searchItems();
        });

        //COMPUTEDS

        const headers = computed(() => {
            let configTbl = !isTotalReception.value ? configTable : configTableTotal;
            return configTbl.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            let configTbl = !isTotalReception.value ? configTable : configTableTotal;
            return formatToDatatable(tableDetailModule.records, configTbl, "status", "", "total");
        });

        const numRecords = computed(() => {
            return tableDetailModule.getTotalRecords
        });

        const recordsPerPage = computed(() => tableDetailModule.pagination.pageSize);

        const currentSelectedPage = computed(() => tableDetailModule.pagination.currentPage);

        const currentDetail = computed(() => tableDetailModule.getRowById(detailId.value) );
        
        //MÉTHODS

        const handleAditionalActionClick = (action, rowID: string) => {
            detailId.value = rowID;
            const article = detailsToReceive.value.find(a => a.Id == rowID);
            const quantityPending = currentDetail.value?.quantityPending ?? 0;
            if (article && article.Cant > 0) { 
                if (quantityPending < article.Cant) {
                    messageService.warning("No se puede recepcionar una cantidad mayor de la pendiente.");
                } else { 
                    Swal.fire({
                        title: `Recepcionar ${article.Cant} unidad(es) de ${article.Name}. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            ApiService.post<{ id: string }>(`ReceiptPurchaseOrders/Detail/${purchaseOrderId.value}`
                                , { productId: article.ProductId, quantity: article.Cant })
                                .then(res => {
                                    if (res.data.id) {
                                        messageService.success("El artículo fue recepcionado exitosamente.");
                                        //Se debe volver a cargar la tabla
                                        tableDetailModule.SEARCH_TABLE_DATA((rowID + "|" + article.Cant));
                                    }
                                }).catch(reject => {
                                    getMessageError(reject, messageService);
                                });
                            //messageService.success("La información se guardó exitosamente.");
                        }
                    });
                }
            }
            else {
                messageService.warning("Debe seleccionar al menos un artículo.");
            }
           
        }

        const handleClickPageDetails = (page: number) => {
            tableDetailModule.CHANGE_PAGE({
                currentPage: page
            })
        }
        const changePageSize = (newPageSize: number) => {
            tableDetailModule.CHANGE_PAGE({
                pageSize: newPageSize
            })
         }

        const onReceptionNumberChange = (id: string, value: number) => {
            
            detailId.value = id;
            const row = currentDetail.value;
            if (row) {
                let detail = detailsToReceive.value.find(d => d.Id == id);
                if(detail == null){
                    detailsToReceive.value.push({ Id: row.id, ProductId: row.productId, Cant: value, Name: row.productName });
                } else {
                    detail.Cant = value;
                }
            }

        }

        const handleCrudAction = () => {
            if (btnForm.value) {
                if(isTotalReception.value)
                    editForm.value.initDefaults();
                btnForm.value.click();
            }
        }
         const columnExcel = [
            {
                columName: 'Nombre Artículo'
                , objectNameValue: 'productName'
            },
            {
                columName: 'Unidad de medida'
                , objectNameValue: 'unitOfMeasurementName'
            },
            {
                columName: 'Cantidad Solicitada'
                , objectNameValue: 'quantity'
            },
            {
                columName: 'Cantidad Recepcionada'
                , objectNameValue: 'quantityReceived'
            },
            {
                columName: 'Cantidad Pendiente'
                , objectNameValue: 'quantityPending'
            }
        ]
        const downloadItemsInfo = async () => {
            const folio = tableModule.getSelectedRow?.folio ?? '';

            createExcelFileUrl(columnExcel, tableDetailModule.records, { fileName: `Artículos_${folio}_${getPurchaseOrderId.value}`, worksheetName: `OC-${folio}` })
        }
        const reloadTableWhenIsCorrect = (isCorrect) => { 
            if (isCorrect) {
                tableDetailModule.SEARCH_TABLE_DATA();
                context.emit("updated-history");
             }
        }

        const formatNumeric = (number) => {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            });
            return formatter.format(number);
        }

        const searchItems = () => {
            if(textSearch.value.trim() != "" && textSearch.value.trim().length >= 3) {
                tableDetailModule.UPDATE_FILTERS({ productName: textSearch.value.trim() });
            }
        }

        const getColorName = (detId) => {
            let tmp = tableDetailModule.allRecords.find(det => det.id == detId);
            return tmp ? tmp.colorName.trim() : ""
        }

        const getCode = (detId) => {
            let tmp = tableDetailModule.allRecords.find(det => det.id == detId);
            return tmp ? tmp.code.trim() : ""
        }

        return {
            headers
            , cantReceive
            , btnForm
            , editForm
            , renderRows
            , numRecords
            , recordsPerPage
            , currentSelectedPage
            , currentDetail
            , idCollapse
            , isTotalReception
            , textSearch
            , handleClickPageDetails
            , handleAditionalActionClick
            , onReceptionNumberChange
            , handleCrudAction
            , changePageSize
            , downloadItemsInfo
            , reloadTableWhenIsCorrect
            , formatNumeric
            , searchItems
            , getColorName
            , getCode
        }
    },
})
