export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "FILTERS": {
            "CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
            "PRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de producto"])}
          }
        },
        "BUTTONS": {
          "FILTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])}
        }
      }
    }
  })
}
