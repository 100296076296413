const buffer = (buffer, fileName: string, fileType: string) => {
    const blob = new Blob([buffer], { type: fileType });

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(blob);

    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
    aElement.remove();
}
 
export { buffer }